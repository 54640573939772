:root {
    /* Breakpoints */
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;

    /* Custom */
    --sidebar-background: #fff;
    --sidebar-background-active: #f3f6f9;
    --mobile-menu-background: var(--primary);
    --header-height: 56px;
    --subheader-height: 56px;
    --menu-width: 265px;
    --menu-width-closed: 70px;
    --content-background: var(--white);
    --smart-border-color: #dee2e6 !important;

    --primary: var(--smart-primary);
    --secondary: var(--smart-secondary);
    --success: var(--smart-success);
    --info: var(--smart-info);
    --warning: var(--smart-warning);
    --danger: var(--smart-error);

    --black: #{$black};
    --black-color: #{$black};
    --white: #{$white};
    --white-color: #{$white};
    --dark: #{$dark};
    --dark-color: #{$dark};
    --light: #{$light};
    --light-color: #{$light};
}

a.text-warning,
.text-warning {
    color: var(--warning) !important;
}

a.text-primary,
.text-primary {
    color: var(--primary) !important;
}

a.text-danger,
.text-danger {
    color: var(--danger) !important;
}

a.text-white,
.text-white {
    color: var(--white) !important;
}

a.text-primary,
.text-primary {
    color: var(--primary) !important;
}

.text-muted {
    color: $text-muted !important;
}

.text-info {
    color: var(--info) !important;
}

.text-success {
    color: var(--success) !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.bg-primary {
    background-color: var(--smart-primary) !important;
}
.bg-success {
    background-color: var(--smart-success) !important;
}

.bg-info {
    background-color: var(--smart-info) !important;
}

.bg-warning {
    background-color: var(--smart-warning) !important;
}

.bg-error {
    background-color: var(--smart-error) !important;
}

.btn-info {
    background-color: var(--smart-info) !important;
    border-color: var(--smart-info) !important;
}

.btn-primary {
    background-color: var(--smart-primary) !important;
    border-color: var(--smart-primary) !important;
}

.btn-success {
    background-color: var(--smart-success) !important;
    border-color: var(--smart-success) !important;
}

small,
.small {
    font-size: 80% !important;
    font-weight: 400 !important;
}

.bg-primary-opacity-2 {
    background: rgba($primary, 0.2);
}

.bg-secondary-opacity-2 {
    background: rgba($secondary, 0.2);
}

.bg-warning-opacity-2 {
    background: rgba($warning, 0.2);
}

.bg-info-opacity-2 {
    background: rgba($info, 0.2);
}

.bg-danger-opacity-2 {
    background: rgba($danger, 0.2);
}

.bg-success-opacity-2 {
    background: rgba($success, 0.2);
}

.bg-primary-opacity-3 {
    background: rgba($primary, 0.3);
}

.bg-secondary-opacity-3 {
    background: rgba($secondary, 0.3);
}

.bg-warning-opacity-3 {
    background: rgba($warning, 0.3);
}

.bg-info-opacity-3 {
    background: rgba($info, 0.3);
}

.bg-danger-opacity-3 {
    background: rgba($danger, 0.3);
}

.bg-success-opacity-3 {
    background: rgba($success, 0.3);
}

.bg-success-opacity-2 {
    background: rgba($success, 0.2);
}

.font-weight-bolder {
    font-weight: bolder;
}

hr {
    border-top: 1px solid rgba(52, 58, 64, 0.1);
    opacity: 1;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.custom-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    -webkit-box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
    box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
    border: 0;
    border-radius: var(--smart-border-radius);
    background: var(--white);

    .card-header {
        padding: 8px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        border: 0;
        margin-bottom: 0;

        .label {
            margin-bottom: 0;
            font-size: 1.09375rem !important;
            display: inline-flex;
            align-items: center;
        }
    }

    .card-header:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }

    .card-body {
        overflow: auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
    }
    .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
}

.card-body {
    overflow: auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.custom-col {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.h-100 {
    height: 100% !important;
}

.shadow-0,
.hover-shadow-0:hover {
    box-shadow: none !important;
}

.img-as-background {
    position: relative;
    overflow: hidden;

    img {
        object-fit: cover;
        position: absolute;
        left: -1px;
        right: 0;
        z-index: 0;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        min-width: calc(100% + 2px);
        min-height: 100%;
        max-width: 105%;
        vertical-align: middle;
        border-style: none;
    }
}

.img-as-background::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.card-header > .settings-button {
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    background: transparent;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 13px;
    border-radius: 5px;
    color: var(--smart-primary);
}

.text-right {
    text-align: right;
}

.rounded-xl {
    border-radius: 12px !important;
}

.text-break-line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 1px 0px;
    text-align: left;
}

.card-header > .settings-button:hover {
    background: var(--smart-primary);
    color: var(--white);
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.justify-content-between {
    justify-content: space-between;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

a.hover-text-warning:hover,
.hover-text-warning:hover {
    color: var(--warning) !important;
}

.font-weight-500 {
    font-weight: 500;
}

.btn-outline-primary {
    color: var(--smart-primary);
    border-color: var(--smart-primary);
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: var(--smart-primary);
    border-color: var(--smart-primary);
}

.border {
    border-color: var(--smart-border-color) !important;
}

.border-top {
    border-color: var(--smart-border-color) !important;
}

.border-bottom {
    border-color: var(--smart-border-color) !important;
}

.border-left {
    border-color: var(--smart-border-color) !important;
}

.border-right {
    border-color: var(--smart-border-color) !important;
}