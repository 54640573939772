body[theme="dark"] {
    --sidebar-background: #1c1c2b;
    --sidebar-background-active: var(--white);
    --mobile-menu-background: #1a1a27;
    --content-background: #21232d;
    --black: #ffffff;
    --black-color: #000000;
    --white: #12141d;
    --white-color: #ffffff;
    --dark: #f8f9fa;
    --dark-color: #343a40;
    --light: #42475b;
    --light-color: #f8f9fa;
    --smart-primary-rgb: inherit;
    --smart-primary: inherit;
    --smart-primary-color: inherit;
    --smart-scroll-bar-track-background: var(--content-background);
    --smart-light: #f8f9fa !important;
    --smart-light-color: #343a40 !important;
    --smart-dark-rgb: 52, 58, 64 !important;
    --smart-dark: #343a40 !important;
    --smart-dark-color: #f8f9fa !important;
    --smart-white-rgb: 255, 255, 255 !important;
    --smart-white: #ffffff !important;
    --smart-white-color: #000000 !important;
    --smart-black-rgb: 0, 0, 0 !important;
    --smart-black: #000000 !important;
    --smart-black-color: #000000 !important;
    --smart-surface: #1c1c2b !important;
    --smart-surface-color: #ffffff !important;
    --smart-background: #1c1c2b !important;
    --smart-background-color: #ffffff !important;
    --smart-border-color: #38444d !important;
    background-color: #1c1c2b !important;
    color: var(--white-color) !important;
}

@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/smart-webcomponents-angular/source/styles/smart.default.css";
@import "../node_modules/smart-webcomponents-angular/source/styles/components/smart.base.css";
@import "../node_modules/smart-webcomponents-angular/source/styles/components/smart.common.css";
@import "../node_modules/smart-webcomponents-angular/source/styles/bootstrap/light/smart.bootstrap.scss";
@import "./assets/css/animations.scss";
@import "./assets/css/jqwidgets.scss";
@import "./assets/css/bootstrap_extend.scss";
@import "./assets/css/global_styles.scss";
